import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import Layout from 'src/components/layout/layout';
import RankingEntry from 'src/components/ranking-entry/ranking-entry';
import USTARankingsRun from 'src/components/rankings-run/rankings-run';
import RankingRun from 'src/components/rankings/ranking-run/ranking-run';
import SEO from 'src/components/seo';
import { usePrivateRoute } from 'src/utils/auth';
import { useV2Rankings } from 'src/utils/useV2Rankings';

const RankingPage = () => {
  usePrivateRoute();

  return (
    <Layout>
      <SEO title="Rankings" />
      <Router basepath="/rankings">
        <RankingRoute path="/:rankingId" />
      </Router>
    </Layout>
  );
};

interface RankingRouteProps extends RouteComponentProps {
  rankingId?: string;
}

const RankingRoute: React.FC<RankingRouteProps> = ({ rankingId }) => {
  if (process.env.GATSBY_CLIENT === 'ITA') {
    return <RankingEntry rankingId={rankingId} />;
  } else {
    return useV2Rankings() ? <RankingRun rankingId={rankingId} /> : <USTARankingsRun rankingId={rankingId} />;
  }
};

export default RankingPage;
