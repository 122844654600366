import React, { useMemo, useState } from 'react';

interface DateTimeOptions {
  locale?: string;
  format?: Intl.DateTimeFormatOptions;
}

interface DateTimeHook {
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  formatDate: (inputDate?: Date) => string;
  parseDate: (input: string) => Date;
  getCurrentDate: () => Date;
  setCurrentDate: () => void;
}

const defaultOptions: DateTimeOptions = {
  locale: 'en-US',
  format: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
};

const useDateTime = (initialDate?: Date, options?: DateTimeOptions): DateTimeHook => {
  const [date, setDate] = useState<Date>(initialDate ?? new Date());
  const mergedOptions = { ...defaultOptions, ...options };

  const formatter = useMemo(
    () => new Intl.DateTimeFormat(mergedOptions.locale, mergedOptions.format),
    [mergedOptions.locale, mergedOptions.format],
  );

  const formatDate = (inputDate?: Date): string => {
    const targetDate = inputDate ?? date;
    return formatter.format(targetDate);
  };

  const parseDate = (input: string): Date => {
    const parsedDate = new Date(input);
    return isNaN(parsedDate.getTime()) ? date : parsedDate;
  };

  const getCurrentDate = (): Date => {
    return new Date();
  };

  const setCurrentDate = (): void => {
    setDate(new Date());
  };

  return {
    date,
    setDate,
    formatDate,
    parseDate,
    getCurrentDate,
    setCurrentDate,
  };
};

export default useDateTime;
