import React, { useEffect } from 'react';

import { Checkbox } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from 'react-i18next';

import { GetOrganisationByTeamId } from '../../../graphql-types/memberships/GetOrganisation';
import { pretifyDivision } from '../../../utils/helper/membership';
import Spinner from '../../spinner/spinner';

type TableProps = {
  team: GetOrganisationByTeamId | undefined;
  loading: boolean;
  setSelectedPlayer: (player) => void;
};

const RankingSelectTeamTable = ({ team, loading, setSelectedPlayer }: TableProps) => {
  const { t } = useTranslation();
  const { t: divisionTranslation } = useTranslation(['divisions']);
  const [selected, setSelected] = React.useState<GetOrganisationByTeamId>();

  const translateDivision = (value?: string) => (value ? divisionTranslation(value) : '');

  const handleClick = (event, team) => {
    setSelected(team);
    setSelectedPlayer(team);
  };

  useEffect(() => {
    //  Reset modal data on close
    setSelected(undefined);
  }, [team]);

  //  Format data for displaying
  const isItemSelected = team?.organisationByTeamId?.id === selected?.organisationByTeamId?.id;
  const division = pretifyDivision(team?.organisationByTeamId?.divisionName || '');
  const playingRegion = translateDivision(team?.organisationByTeamId?.schools[0].itaRegion);
  const rankingAwardRegion = translateDivision(team?.organisationByTeamId?.schools[0].rankingAwardRegion);
  const conferenceName = translateDivision(team?.organisationByTeamId?.conferenceName);

  return (
    <>
      {loading && <Spinner />}
      {!loading && team?.organisationByTeamId && (
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>{t('team')}</TableCell>
                <TableCell>{t('division')}</TableCell>
                <TableCell>{t('playing region')}</TableCell>
                <TableCell>{t('ranking award region')}</TableCell>
                <TableCell>{t('conference')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow onClick={(event) => handleClick(event, team)} hover>
                <TableCell padding="checkbox">
                  <Checkbox color="primary" checked={isItemSelected} />
                </TableCell>
                <TableCell component="th" scope="row">
                  {team?.organisationByTeamId?.name}
                </TableCell>
                <TableCell>{division}</TableCell>
                <TableCell>{playingRegion}</TableCell>
                <TableCell>{rankingAwardRegion}</TableCell>
                <TableCell>{conferenceName}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default RankingSelectTeamTable;
