import React, { ReactNode, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { RankListStatusEnum } from 'src/graphql-types/globalITARankingTypes';
import ROUTES from 'src/utils/routes';
import * as yup from 'yup';

import {
  BodyRegular,
  BodyRegularBold,
  BodySmall,
  BodySmallBold,
  TextInput,
} from '@clubspark-react/clubspark-react-tools';

import Button from '../button/button';
import { CustomGrid } from '../custom-grid/custom-grid';
import FormErrorMessage from '../form-error-message/form-error-message';
import Select from '../mui-select/mui-select';
import { getTotalItems, transformVariables } from '../team-rankings/team-rankings';
import { DELETE_RANK_LIST, GET_RANK_LISTS } from '../team-rankings/team-rankings-queries';
import * as styles from './ranking-entry-helper.module.less';

export const reorderItemsInsideTable = (list, startIndex, endIndex) => {
  const listCopy = list.slice();
  const [removed] = listCopy.splice(startIndex, 1);
  listCopy.splice(endIndex, 0, removed);
  return listCopy;
};

export const onDrop = (dragObject, dropType, dropRowId, endIndex, list, setList) => {
  const startIndex = dragObject.startIndex;

  const reorderedList = reorderItemsInsideTable(list, startIndex, endIndex);

  setList(reorderedList);
};

interface InfoLabelItemProps {
  label: string;
  value: string | ReactNode;
  styleOverride?: string;
  status?: boolean;
}

export const InfoLabelItem = ({ label, value, styleOverride, status = false }: InfoLabelItemProps) => {
  return (
    <div className={styleOverride ? styles.override : styles.infoLabelItem}>
      <BodySmallBold>{label}</BodySmallBold>
      {status ? { value } : <BodySmall>{value}</BodySmall>}
    </div>
  );
};

export const ChangePositionPopover = ({ list, row, movePositionNumber, setAnchorEl, isTeam, tableData }) => {
  const { t } = useTranslation();
  const [sameIndexError, setSameIndexError] = useState(false);

  interface InitialValues {
    newIndex: string;
  }

  const initialValues: InitialValues = {
    newIndex: '',
  };

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        newIndex: yup
          .number()
          .typeError(t('must be number'))
          .min(1, t('position number must'))
          .max(tableData.length, t('must be lower'))
          .required(t('is required', { type: 'new position number' })),
      }),
    [tableData],
  );

  const handleSubmit = (values: InitialValues, actions: FormikHelpers<InitialValues>) => {
    movePositionNumber(list, row, Number(values.newIndex));
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
      validationSchema={validationSchema}
    >
      {({ values, errors, touched, submitForm }) => (
        <Form>
          <Grid container className={styles.popoverContainer} direction="column">
            <BodyRegularBold spacing={{ margins: { xs: 'bottom' } }}>{t('move to position')}</BodyRegularBold>
            <Field
              name="newIndex"
              disableUnderline
              outlined
              placeholder={t('new position number')}
              component={TextInput}
            />
            {errors?.newIndex && touched?.newIndex && (
              <FormErrorMessage spacing={{ margins: { xs: 'top' } }} message={errors?.newIndex} />
            )}
            <CustomGrid container justify="flex-end" spacing={{ margins: { sm: 'top' } }}>
              <Button spacing={{ margins: { sm: 'right' } }} level="tertiary" onClick={() => setAnchorEl(null)}>
                {t('close')}
              </Button>
              <Button type="submit">{isTeam ? t('move team') : t('move player')}</Button>
            </CustomGrid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export const DeleteDialog = ({ name, id, setDeleteDialog }) => {
  const { t } = useTranslation();
  const [deleteRankingList, { loading: deleteRankingListLoading }] = useMutation(DELETE_RANK_LIST);

  async function handleClick() {
    await deleteRankingList({
      variables: {
        id,
      },
    });
    setDeleteDialog(false);
    navigate(ROUTES.RANKINGS);
  }

  return (
    <Grid container direction="column">
      <BodyRegular spacing={{ margins: { xs: 'bottom' } }}>{t('confirm delete ranking')}</BodyRegular>
      <BodyRegular classnames={styles.breakWordContainer} spacing={{ margins: { lg: 'bottom' } }}>
        {name}
      </BodyRegular>
      <CustomGrid container justify="flex-end" alignItems="center">
        <Button
          level="tertiary"
          spacing={{ margins: { sm: 'right' } }}
          onClick={() => setDeleteDialog(false)}
          disabled={deleteRankingListLoading}
        >
          {t('no, cancel')}
        </Button>
        <Button level="warningOutlined" onClick={handleClick} loading={deleteRankingListLoading}>
          {t('yes, delete')}
        </Button>
      </CustomGrid>
    </Grid>
  );
};

interface PublishDialogInterface {
  name: string;
  isPublished?: boolean;
}

export function PublishDialog({ name, isPublished = false }: PublishDialogInterface) {
  const { t } = useTranslation();

  return (
    <Grid container direction="column">
      <BodyRegular spacing={{ margins: { xs: 'bottom' } }}>
        {isPublished ? t('confirm hide ranking') : t('confirm publish ranking')}
      </BodyRegular>
      <BodyRegular spacing={{ margins: { lg: 'bottom' } }}>{name}</BodyRegular>
    </Grid>
  );
}

export const AddPlayersDialog = ({ setPlayersDialog, addPlayerRows, tableData, isTeam }) => {
  const { t } = useTranslation();
  const initialValues = {
    where: '',
    numPlayers: '',
    posNumber: '',
  };

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        numPlayers: yup
          .number()

          .typeError(t('must be number'))
          .required(
            t('is required', {
              type: isTeam ? 'number of teams' : 'number of players',
            }),
          ),
        ...(tableData?.length
          ? {
              where: yup.string().required(t('is required', { type: 'where' })),
              posNumber: yup
                .number()
                .typeError(t('must be a number'))
                .min(1, t('position number must'))
                .max(tableData.length, t('must be lower'))
                .required(t('is required', { type: 'position number' })),
            }
          : {}),
      }),
    [tableData, t],
  );

  const handleSubmit = (values, actions) => {
    const { where, numPlayers, posNumber } = values;
    addPlayerRows({ where, numOfPlayers: Number(numPlayers), posNumber });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
      validationSchema={validationSchema}
    >
      {({ setFieldValue, values, errors, touched }) => (
        <Form>
          <Grid container direction="row" justify="space-between">
            <Grid item className={styles.addPlayerRowsItem}>
              <BodySmallBold spacing={{ margins: { xxs: 'bottom' } }}>
                {isTeam ? t('number of teams') : t('number of players')}
              </BodySmallBold>
              <Field
                name="numPlayers"
                disableUnderline
                fullWidth
                outlined
                placeholder={t('enter a number')}
                component={TextInput}
              />
              {errors?.numPlayers && touched?.numPlayers && (
                <FormErrorMessage spacing={{ margins: { xs: 'top' } }} message={errors?.numPlayers} />
              )}
              {tableData?.length > 0 && (
                <>
                  <BodySmallBold spacing={{ margins: { xxs: 'bottom', sm: 'top' } }}>{t('where')}</BodySmallBold>
                  <Field
                    name="where"
                    onSelect={(option) => setFieldValue('where', option.value)}
                    options={[
                      { value: 'Above position', label: t('above position') },
                      { value: 'Below position', label: t('below position') },
                    ]}
                    component={Select}
                  />
                </>
              )}
            </Grid>
            {values.where && tableData?.length > 0 && (
              <Grid item style={{ marginTop: 'auto' }} className={styles.addPlayerRowsItem}>
                <BodySmallBold spacing={{ margins: { xxs: 'bottom' } }}>{t('position number')}</BodySmallBold>
                <Field name="posNumber" disableUnderline fullWidth outlined component={TextInput} />
              </Grid>
            )}
            <Grid container justify="space-between">
              {errors?.where && touched?.where && (
                <FormErrorMessage spacing={{ margins: { xs: 'top' } }} message={errors?.where} />
              )}
              {values.where && <FormErrorMessage spacing={{ margins: { xs: 'top' } }} message={errors?.posNumber} />}
            </Grid>
            <CustomGrid container justify="flex-end" spacing={{ margins: { sm: 'top' } }}>
              <Button level="tertiary" spacing={{ margins: { sm: 'right' } }} onClick={() => setPlayersDialog(false)}>
                {t('cancel')}
              </Button>
              <Button type="submit">{isTeam ? t('add team rows') : t('add player rows')}</Button>
            </CustomGrid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export function isPublished(status?: RankListStatusEnum | null) {
  return status === RankListStatusEnum.Published;
}
