import React from 'react';

import TableCell from '@material-ui/core/TableCell';

import { CellConfigInterface } from '../virtualized-table/typedefs';
import { ColumnData, RowData } from '../virtualized-table/virtualized-table';

interface TableHeadCellProps<T extends RowData> {
  cellConfig: CellConfigInterface;
  column: ColumnData<T>;
}

const TableHeadCell = <T extends RowData>({ cellConfig, column }: TableHeadCellProps<T>) => {
  const className = column.getTitle()?.className;
  return (
    <TableCell
      className={`${cellConfig?.className ? cellConfig.className : ''}${className ? ` ${className}` : ''}`}
      component="div"
      id={column.key}
      key={column.key}
    >
      <div>{column.getTitle()?.node || ''}</div>
    </TableCell>
  );
};

export default TableHeadCell;
